import { ChangeEvent, FC, useCallback, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

interface MultiRangeSliderProps {
  min: number;
  max: number;
  step: number;
  onChangeFunction: Function;
  name: string;
  placeholder: string;
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({ min, max, onChangeFunction, step, name, placeholder }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);

  // Convert to percentage
  const getPercent = useCallback((value: number) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChangeFunction({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChangeFunction]);

  return (
    <div className='flex items-center justify-center relative'>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={minVal}
        ref={minValRef}
        name={`min-range-${name}`}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          event.target.value = value.toString();
        }}
        className={classnames('z-[3] w-full thumb', minVal > max - 100 && 'z-[5]')}
        placeholder={`Min ${placeholder}`}
      />
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={maxVal}
        ref={maxValRef}
        name={`max-range-${name}`}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          event.target.value = value.toString();
        }}
        className='z-[4] w-full thumb'
        placeholder='Max Twitter Followers'
      />

      <div className='relative w-full -mt-1'>
        <div className='h-[5px] rounded absolute bg-gray-200 dark:bg-white w-full z-[1]'></div>
        <div ref={range} className='absolute h-[5px] rounded z-[2] bg-red-500'></div>
        <div className='absolute left-1 text-xs text-slate-400 mt-3'>
          <NumberFormat value={minVal} displayType={'text'} decimalScale={2} thousandSeparator={true} />
        </div>
        <div className='absolute right-1 text-xs text-slate-400 mt-3'>
          <NumberFormat value={maxVal} displayType={'text'} decimalScale={2} thousandSeparator={true} />
        </div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
