import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { RankingProps } from '../types';
import NumberFormat from 'react-number-format';
import DiscordIcon from './icons/Discord';
import TwitterIcon from './icons/Twitter';
import MultiRangeSlider from './MultiRangeSlider';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export default function MainTable({ communities }: RankingProps) {
  const router = useRouter();
  const { data: session, status } =
    useSession(/* {
    required: true,
    onUnauthenticated() {
      router.push('/login');
    },
  } */);
  const loginOrGoToCommunity = useCallback(
    (slug: string) => {
      if (!session) return router.push('/login');
      return router.push(`/community/${slug}`);
    },
    [session, router]
  );
  const [search, setSearch] = useState<string>('');
  const twitterFollowersMin = 5000;
  const twitterFollowersMax = 1000000;
  const [twitterFollowersMinVal, setTwitterFollowersMinVal] = useState(twitterFollowersMin);
  const [twitterFollowersMaxVal, setTwitterFollowersMaxVal] = useState(twitterFollowersMax);
  const changeTwitterFollowersMinOrMax = useCallback(
    ({ min, max }: { min: number; max: number }) => {
      if (min !== twitterFollowersMinVal) {
        setTwitterFollowersMinVal(min);
      }
      if (max !== twitterFollowersMaxVal) {
        setTwitterFollowersMaxVal(max);
      }
    },
    [twitterFollowersMaxVal, twitterFollowersMinVal, setTwitterFollowersMaxVal, setTwitterFollowersMinVal]
  );
  const discordMembersMin = 1000;
  const discordMembersMax = 250000;
  const [discordMembersMinVal, setDiscordMembersMinVal] = useState(discordMembersMin);
  const [discordMembersMaxVal, setDiscordMembersMaxVal] = useState(discordMembersMax);
  const changeDiscordMembersMinOrMax = useCallback(
    ({ min, max }: { min: number; max: number }) => {
      if (min !== discordMembersMinVal) {
        setDiscordMembersMinVal(min);
      }
      if (max !== discordMembersMaxVal) {
        setDiscordMembersMaxVal(max);
      }
    },
    [discordMembersMinVal, discordMembersMaxVal, setDiscordMembersMinVal, setDiscordMembersMaxVal]
  );
  const [growthRate, setGrowthRate] = useState<string>('');
  const [engagementRate, setEngagementRate] = useState<string>('');
  const [filteredCommunities, setFilteredCommunities] = useState(communities);

  useEffect(() => {
    let filteringCommunities = communities;
    if (search) {
      console.log('search filter being applied');
      filteringCommunities = filteringCommunities.filter((community) => community.name.toLowerCase().includes(search.toLowerCase()));
    }

    if (twitterFollowersMinVal !== twitterFollowersMin || twitterFollowersMaxVal !== twitterFollowersMax) {
      console.log('twitter followers filter being applied');
      filteringCommunities = filteringCommunities.filter((community) => Number(community.twitter_followers_count) >= twitterFollowersMinVal && Number(community.twitter_followers_count) <= twitterFollowersMaxVal);
    }

    if (discordMembersMinVal !== discordMembersMin || discordMembersMaxVal !== discordMembersMax) {
      console.log('discord members filter being applied');
      filteringCommunities = filteringCommunities.filter((community) => Number(community.discord_followers_count) >= discordMembersMinVal && Number(community.discord_followers_count) <= discordMembersMaxVal);
    }

    if (growthRate) {
      console.log('growth rate filter being applied');
      filteringCommunities = filteringCommunities.filter((community) => {
        const twitterGrowth = Number(community.twitter_growth_rate);
        const discordGrowth = Number(community.discord_growth_rate);
        // ['Low', 'Medium', 'High', 'Suspiciously High']
        switch (growthRate) {
          case 'Low':
            return twitterGrowth > 0 || discordGrowth > 0;
          case 'Medium':
            return (twitterGrowth > 0 || discordGrowth > 0) && (twitterGrowth < 0.5 || discordGrowth < 0.5);
          case 'High':
            return (twitterGrowth > 0 || discordGrowth > 0) && (twitterGrowth < 1 || discordGrowth < 1);
          case 'Suspiciously High':
            return (twitterGrowth > 0 || discordGrowth > 0) && (twitterGrowth < 1.5 || discordGrowth < 1.5);
          default:
            return true;
        }
      });
    }

    if (engagementRate) {
      console.log('engagement rate filter being applied');
      filteringCommunities = filteringCommunities.filter((community) => {
        const twitterEngagementRate = Number(community.twitter_engagement_rate);

        // ['Low', 'Medium', 'High', 'Suspiciously High']
        switch (engagementRate) {
          case 'Low':
            return twitterEngagementRate > 0 && twitterEngagementRate < 0.1;
          case 'Medium':
            return twitterEngagementRate >= 0.1 && twitterEngagementRate < 0.4;
          case 'High':
            return twitterEngagementRate >= 0.4 && twitterEngagementRate < 0.8;
          case 'Suspiciously High':
            return twitterEngagementRate >= 0.8;
          default:
            return true;
        }
      });
    }

    setFilteredCommunities(filteringCommunities);
  }, [communities, search, growthRate, engagementRate, twitterFollowersMinVal, twitterFollowersMaxVal, discordMembersMinVal, discordMembersMaxVal]);

  return (
    <>
      <div className='mx-2 sm:mx-0 md:gap-x-4 gap-y-4 md:gap-y-0 grid grid-cols-1 md:grid-cols-3'>
        <div>
          <label htmlFor='search' className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
            Search
          </label>
          <div className='mt-1'>
            <input type='text' name='search' id='search' className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md' placeholder='' onChange={(e) => setSearch(e.target.value)} />
          </div>
        </div>
        <div className='mt-4 sm:mt-0 grid gap-y-12 sm:gap-y-0 sm:gap-x-4 sm:grid-cols-4 col-span-2'>
          <div>
            <label htmlFor='min-range-twitter-followers' className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
              Twitter Followers
            </label>
            <div className='mt-3'>
              <MultiRangeSlider min={twitterFollowersMin} max={twitterFollowersMax} step={1000} name={'twitter-followers'} placeholder={'Twitter Followers'} onChangeFunction={changeTwitterFollowersMinOrMax} />
            </div>
          </div>
          <div>
            <label htmlFor='min-range-discord-members' className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
              Discord Members
            </label>
            <div className='mt-3'>
              <MultiRangeSlider min={discordMembersMin} max={discordMembersMax} step={1000} name={'discord-members'} placeholder={'Discord Members'} onChangeFunction={changeDiscordMembersMinOrMax} />
            </div>
          </div>
          <div>
            <Listbox value={growthRate} onChange={setGrowthRate}>
              {({ open }) => (
                <>
                  <Listbox.Label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>Growth Rate</Listbox.Label>
                  <div className='mt-1 relative'>
                    <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm'>
                      <span className='block truncate'>{growthRate || '-'}</span>
                      <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                        <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                      </span>
                    </Listbox.Button>

                    <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
                      <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                        {['', 'Low', 'Medium', 'High', 'Suspiciously High'].map((person, index) => (
                          <Listbox.Option key={index} className={({ active }) => classNames(active ? 'text-white bg-red-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')} value={person}>
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{person || 'None'}</span>

                                {selected ? (
                                  <span className={classNames(active ? 'text-white' : 'text-red-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                    <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          <div>
            <Listbox value={engagementRate} onChange={setEngagementRate}>
              {({ open }) => (
                <>
                  <Listbox.Label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>Engagement Rate</Listbox.Label>
                  <div className='mt-1 relative'>
                    <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm'>
                      <span className='block truncate'>{engagementRate || '-'}</span>
                      <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                        <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                      </span>
                    </Listbox.Button>

                    <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
                      <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                        {['', 'Low', 'Medium', 'High', 'Suspiciously High'].map((person, index) => (
                          <Listbox.Option key={index} className={({ active }) => classNames(active ? 'text-white bg-red-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')} value={person}>
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{person || 'None'}</span>

                                {selected ? (
                                  <span className={classNames(active ? 'text-white' : 'text-red-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                    <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
      </div>
      <div className='mt-8 overflow-hidden shadow ring-1 ring-black dark:ring-white ring-opacity-5 sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-700 dark:divide-gray-400'>
          <thead className='bg-gray-50 dark:bg-slate-800'>
            <tr>
              <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50 sm:pl-6'>
                Name
              </th>
              <th scope='col' className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50 sm:table-cell'>
                Followers
              </th>
              <th scope='col' className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50 sm:table-cell'>
                Growth
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50'>
                Engagement Rate
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-slate-800'>
            {filteredCommunities.length > 0 ? (
              filteredCommunities.map((community) => (
                <tr key={community.name} className='cursor-pointer' onClick={() => loginOrGoToCommunity(community.project_id)}>
                  <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-50 sm:w-auto sm:max-w-none sm:pl-6'>
                    {community.name}
                    <dl className='font-normal lg:hidden'>
                      <dt className='sr-only'>Twitter Followers</dt>
                      <dd className='mt-1 truncate text-gray-500 sm:hidden flex items-center'>
                        <TwitterIcon /> Followers <NumberFormat className='ml-1' value={community.twitter_followers_count} displayType={'text'} thousandSeparator={true} />
                      </dd>
                      <dt className='sr-only'>Discord Followers</dt>
                      <dd className='mt-1 truncate text-gray-500 sm:hidden flex items-center'>
                        <DiscordIcon /> Followers <NumberFormat className='ml-1' value={community.discord_followers_count} displayType={'text'} thousandSeparator={true} />
                      </dd>
                      <dt className='sr-only sm:hidden'>Twitter Growth</dt>
                      <dd className='mt-1 truncate text-gray-500 sm:hidden flex items-center'>
                        <TwitterIcon />
                        Growth <NumberFormat className='ml-1' value={community.twitter_growth_rate} displayType={'text'} decimalScale={2} suffix={'%'} thousandSeparator={true} />
                      </dd>
                      <dt className='sr-only sm:hidden'>Discord Growth</dt>
                      <dd className='mt-1 truncate text-gray-500 sm:hidden  flex items-center'>
                        <DiscordIcon /> Growth <NumberFormat className='ml-1' value={community.discord_growth_rate} displayType={'text'} decimalScale={2} suffix={'%'} thousandSeparator={true} />
                      </dd>
                    </dl>
                  </td>
                  <td className='hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'>
                    <div className='flex items-center'>
                      <TwitterIcon />
                      <NumberFormat value={community.twitter_followers_count} displayType={'text'} thousandSeparator={true} />
                    </div>
                    <div className='flex items-center'>
                      <DiscordIcon />
                      <NumberFormat value={community.discord_followers_count} displayType={'text'} thousandSeparator={true} />
                    </div>
                  </td>
                  <td className='hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'>
                    <div className='flex items-center'>
                      <TwitterIcon />
                      <NumberFormat value={community.twitter_growth_rate} displayType={'text'} decimalScale={2} suffix={'%'} thousandSeparator={true} />
                    </div>
                    <div className='flex items-center'>
                      <DiscordIcon />
                      <NumberFormat value={community.discord_growth_rate} displayType={'text'} decimalScale={2} suffix={'%'} thousandSeparator={true} />
                    </div>
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    <div className='flex items-center'>
                      <TwitterIcon />
                      <NumberFormat value={community.twitter_engagement_rate} displayType={'text'} decimalScale={2} suffix={'%'} thousandSeparator={true} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className='text-center p-4 text-sm font-medium text-gray-900 dark:text-gray-500 uppercase' colSpan={4}>
                  {communities.length === 0 ? 'No communities found' : 'No communities match your search'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
