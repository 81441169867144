import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MainTable from '../components/MainTable';
import { Ranking, RankingProps, RankingResults } from '../types';

const Home: NextPage<RankingProps> = ({ communities }) => {
  return (
    <>
      <Head>
        <title>TrackNFTs</title>
        <meta name='description' content='TrackNFTs' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <div className='min-h-full bg-white dark:bg-slate-900'>
        <Header />
        <main className='py-10'>
          <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
            <MainTable communities={communities} />
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Home;

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export async function getStaticProps() {
  let communities;
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}ranking/`);
    if (!res.ok) {
      // If there is a server error, you might want to
      // throw an error instead of returning so that the cache is not updated
      // until the next successful request.
      throw new Error(`Failed to fetch projects, received status ${res.status}`);
    }
    // const resultsJson = (await res.json()) as RankingResults;
    // communities = resultsJson.results;
    communities = (await res.json()) as Ranking[];
  } catch (error) {
    console.error(error);
    communities = [] as Ranking[];
  }

  return {
    props: {
      communities,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 10, // In seconds
  };
}
